import { useState } from "react";
import {
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "react-query";
import { authAxios } from "utility/axios";

import {
  deserializeQuestion,
  deserializeQuestionList,
  QuestionDetails,
  QuestionId,
  QuestionListItem,
  Question,
  serializeQuestion,
  QuestionListResponse,
} from "./";
import { QuestionContext } from "./interfaces";
import {
  QuestionFilterState,
  serializeQuestionFilters,
} from "./question-filters";
import { PageData, paginateArray } from "store/pagination";
import { JobId } from "store/job/interfaces";
import { reference } from "@popperjs/core";

export function getQuestionBaseUrl(context: QuestionContext) {
  return {
    job: "jobtests/jobad-question/",
    test: "jobtests/question/",
    "reference-check": "jobtests/question/",
    company: "jobtests/questionnaire/",
  }[context];
}

export async function getQuestions<Context extends QuestionContext>(
  context: Context,
  filters?: QuestionFilterState,
): Promise<PageData<QuestionListItem<Context>>> {
  const { data } = await authAxios.get<QuestionListResponse>(
    getQuestionBaseUrl(context),
    {
      params: serializeQuestionFilters(filters),
    },
  );
  return paginateArray(deserializeQuestionList(data, context), {
    count: data.count,
    offset: filters?.offset,
    limit: filters?.limit,
    next: data.next,
    previous: data.previous,
  });
}

export async function getQuestion<Context extends QuestionContext>(
  context: Context,
  id: QuestionId,
): Promise<QuestionDetails<Context>> {
  const { data } = await authAxios.get(`${getQuestionBaseUrl(context)}${id}/`);
  return deserializeQuestion(data, context);
}

export function useQuestions<Context extends QuestionContext>(
  context: Context,
  filters?: QuestionFilterState,
) {
  return useQuery([`${context}-question`, "list", filters], () =>
    getQuestions(context, filters),
  );
}

export function useInfiniteQuestions<Context extends QuestionContext>(
  context: Context,
  filters?: Omit<QuestionFilterState, "offset" | "limit">,
) {
  return useInfiniteQuery(
    [`${context}-question`, "list", filters],
    ({ pageParam: offset = 0 }) =>
      getQuestions(context, { ...filters, offset, limit: 4 }),
    {
      getPreviousPageParam: (firstPage) =>
        firstPage.pagination.offset > 0
          ? firstPage.pagination.offset - firstPage.pagination.limit
          : undefined,
      getNextPageParam: (lastPage) =>
        lastPage.pagination.offset + lastPage.pagination.limit <=
        lastPage.pagination.count
          ? lastPage.pagination.offset + lastPage.pagination.limit
          : undefined,
    },
  );
}

export function useQuestion<Context extends QuestionContext>(
  originalContext: Context,
  id: QuestionId,
  options?: UseQueryOptions<QuestionDetails<Context>>,
) {
  const context =
    originalContext === "reference-check" ? "test" : originalContext;

  return useQuery(
    [`${context}-question`, "item", id],
    () => getQuestion(context, id),
    {
      enabled: Boolean(id),
      ...options,
    },
  );
}

export function useQuestionManager<Context extends QuestionContext>(
  context: Context,
) {
  const queryClient = useQueryClient();
  const invalidateQueries = (questionId: QuestionId) => {
    queryClient.invalidateQueries([`${context}-question`, "list"]);
    queryClient.invalidateQueries([`${context}-question`, "item", questionId]);
  };

  const [hoveredQuestion, setHoveredQuestion] = useState<QuestionId | null>();
  useQuestion(context, hoveredQuestion);

  return {
    create: async (questionData: Question) => {
      const { data } = await authAxios.post(
        `${getQuestionBaseUrl(context)}`,
        serializeQuestion(questionData),
        {
          waitMessage: "Saving question...",
        },
      );
      invalidateQueries(data.id);
      return deserializeQuestion(data, context);
    },
    update: async (questionData: Question) => {
      const { data } = await authAxios.patch(
        `${getQuestionBaseUrl(context)}${questionData.id}/`,
        serializeQuestion(questionData),
      );

      invalidateQueries(questionData.id);
      queryClient.refetchQueries([`${context}-question`, "list"]);

      return deserializeQuestion(data, context);
    },
    delete: async (questionId: QuestionId) => {
      await authAxios.delete(`${getQuestionBaseUrl(context)}${questionId}/`);
      invalidateQueries(questionId);
      if (context === "job") {
        queryClient.invalidateQueries(["job", "item", questionId]);
      }
    },
    restore: async (questionId: QuestionId, jobId: JobId = null) => {
      await authAxios.patch(
        `${getQuestionBaseUrl(context)}${questionId}/`,
        {},
        {
          params: {
            archived: true,
          },
        },
      );

      invalidateQueries(questionId);
    },
    setHoveredQuestion,
  };
}
