import { JobId } from "store/job/interfaces";
import { UserType } from "store/user";
import { TestTakerFlowProps } from "./interfaces";
import { User } from "@sentry/nextjs";
import { ApplicantId } from "store/applicant";
import { JobApplicantId } from "store/job-applicant";
import { AssessmentId } from "store/assessment/interfaces";
import { TestId } from "store/test";

export const userRoot = {
  [UserType.Admin]: "/admin",
  [UserType.Employer]: "/employer/jobs",
  [UserType.JobSeeker]: "/applicant",
};

export enum AdminRoutes {
  AssessmentList = "/admin/assessments",
  CreateAssessment = "/admin/assessments/create-assessment",
  EmailList = "/admin/emails",
  CreateEmail = "/admin/emails/create-email",
  Marketplace = "/admin/marketplace",
}
export enum EmployerRoutes {
  AssessmentList = "/employer/assessments?name=_",
  CreateAssessment = "/employer/assessments/create-assessment",
  JobList = "/employer/jobs?title=_",
  CreateJob = "/employer/jobs/create-job",
  EmailList = "/employer/emails?title=_",
  ApplicantList = "/employer/applicants?combo=_",
  MarketplaceApplicantList = "/employer/marketplace/applicants?combo=_",
  CreateEmail = "/employer/emails/create-email",
  Settings = "/employer/settings/profile",
  CreateAssessmentJob = `/employer/jobs/create-assessment-job`,
  AssessmentJobCreated = `/employer/jobs/assessment-job-created`,
  SettingsWorkspace = `/employer/settings/workspace`,
  SettingsProfile = `/employer/settings/profile`,
  SettingsTeam = `/employer/settings/team`,
  Payment = `/employer/settings/payment`,
  Plans = `/employer/settings/plans`,
  ReferenceCheckForms = `/employer/reference-check-forms/`,
  CreateReferenceCheckForm = `/employer/reference-check-forms/create`,
  Interviews = `/employer/interview/:interviewCandidateId`,
}

export enum PublicRoutes {
  Login = "/auth/login",
  RegisterJobSeeker = "/register/job-seeker",
  RegisterEmployer = "/register/employer",
  TalkToUs = "/talk-to-us/",
  TermsOfService = "/terms-of-service/",
}

export class GetApplicantRoleUrl {
  static forJobApplication(jobId: JobId) {
    return `/jobs/application/${jobId}`;
  }
  static forTestTakerFlow({
    jobApplicantId,
    testId,
    jobId,
  }: TestTakerFlowProps) {
    return jobApplicantId
      ? `/applicant/${jobApplicantId}/tests/${testId}`
      : `/tests/${testId}`;
  }
}

export class GetEmployerRoleUrl {
  static forApplicantList(user: User) {
    return user.defaultApplicantPage === "list"
      ? "/employer/applicants?combo=_"
      : "/employer/applicants/pipeline-view";
  }
  static forMarketplaceApplicantDetails(applicantId: ApplicantId) {
    return `/employer/marketplace/applicant/${applicantId}`;
  }
  static forJobApplicantDetails(jobApplicantId: JobApplicantId) {
    return `/employer/jobs/applicant/${jobApplicantId}`;
  }

  static forPreviewAssessment(assessmentId: AssessmentId) {
    return `/employer/assessments/preview/${assessmentId}`;
  }

  static forPreviewAssessmentTest(testId: TestId, assessmentId: AssessmentId) {
    return `/employer/assessments/preview/test/${testId}?assessmentId=${assessmentId}`;
  }
  static forAssessmentJobCreated(assessmentJobId: JobId) {
    return `/employer/jobs/assessment-job-created?jobId=${assessmentJobId}`;
  }
  static forEditAssessment(assessmentId: AssessmentId) {
    return `/employer/assessments/${assessmentId}`;
  }
  static forEditReferenceCheckForm(assessmentId: AssessmentId) {
    return `/employer/reference-check-forms/${assessmentId}`;
  }
}

export function removeQueryParams(url: string): string {
  if (url.includes("?")) {
    return url.split("?")[0];
  } else {
    return url;
  }
}
