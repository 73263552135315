import { Contact } from "store/contact";
import { LocationType, PositionType } from "store/job/enums";
import { PaginatedList } from "store/pagination";
import { UserId } from "store/user";

import {
  Applicant,
  ApplicantEducation,
  ApplicantExperience,
  ApplicantLocation,
  ApplicantPayment,
  ApplicantPosition,
  ApplicantResume,
  EmployerJobApplicantTestStats,
  TestAttempt,
  TestHistory,
  TestHistoryQuestion,
} from "./applicant-models";
import { ApplicantRequest } from "./applicant-requests";
import {
  ApplicantEducationResponse,
  ApplicantExperienceResponse,
  ApplicantLocationResponse,
  ApplicantPaymentResponse,
  ApplicantPositionResponse,
  ApplicantResponse,
  JobApplicantTestStatsResponse,
  TestAttemptResponse,
  TestHistoryQuestionResponse,
  TestHistoryResponse,
} from "./applicant-responses";
import { deserializeJobApplicantReference } from "store/job-applicant";
import { serializeApplicantReference } from "store/own-job-applicant/own-job-applicant-serialization";
import { getExt } from "utility/uploads/functions";
import { setHttp } from "utility/links";
import { deserializeQuestionType, QuestionType } from "store/question";
import { getText } from "utility/html";

export function deserializeApplicant(response: ApplicantResponse): Applicant {
  return {
    id: response.id,
    createdAt: new Date(response.created_at),
    updatedAt: new Date(response.updated_at),
    educations: response.educations.map((e) => deserializeEducation(e)),
    skills: response.skills,
    experiences: response.experiences.map((e) => deserializeExperience(e)),
    experience: response.experience,
    summary: response.summary,
    owner: response.owner as UserId,
    resumes: response.resumes,
    payment: response.payment
      ? deserializeApplicantPayment(response.payment)
      : null,
    position: response.position
      ? deserializeApplicantPosition(response.position)
      : null,
    location: response.location
      ? deserializeApplicantLocation(response.location)
      : null,
    references:
      response.references?.map(deserializeJobApplicantReference) || [],
  };
}

export function deserializeApplicantPayment(
  response: ApplicantPaymentResponse,
): ApplicantPayment {
  return {
    annual: response.annual,
    currency: response.currency,
    hourly: response.hourly,
    isVisible: response.is_visible,
  };
}
export function deserializeApplicantPosition(
  response: ApplicantPositionResponse,
): ApplicantPosition {
  return {
    preferred: response.preferred,
    isVisible: response.is_visible,
    workingHours: response.number_of_hours,
  };
}
export function deserializeApplicantLocation(
  response: ApplicantLocationResponse,
): ApplicantLocation {
  return {
    preferred: response.preferred,
    isVisible: response.is_visible,
  };
}

export function deserializeEducation(
  response: ApplicantEducationResponse,
): ApplicantEducation {
  return {
    rank: response.rank,
    gotDegree: response.got_degree,
    education: response.education,
    startingDate: response.starting_date,
    endingDate: response.ending_date,
  };
}
export function deserializeExperience(
  response: ApplicantExperienceResponse,
): ApplicantExperience {
  return {
    company: response.company,
    endingDate: response.ending_date ? new Date(response.ending_date) : null,
    headline: response.headline,
    startingDate: new Date(response.starting_date),
    summary: response.summary,
  };
}

export function serializeApplicant(data: Applicant): ApplicantRequest {
  return {
    id: data.id,
    educations: data.educations,
    skills: data.skills,
    experience: data.experience,
    summary: data.summary,
    references: data.references.map(serializeApplicantReference),
    resumes: serializeResumeList(data.resumes),
  };
}

export function serializeResume(applicantResume: ApplicantResume) {
  const extension = getExt(applicantResume.resume.title);
  const fileTitleShort = applicantResume.resume.title.slice(0, 50);
  const title = fileTitleShort.includes(extension)
    ? fileTitleShort
    : `${fileTitleShort.slice(0, 49 - extension.length)}.${extension}`;

  return {
    id: applicantResume.id,
    resume: {
      id: applicantResume.resume.id,
      title,
      file: applicantResume.resume.file,
    },
  };
}

export function serializeResumeList(applicantResumes: ApplicantResume[]) {
  return applicantResumes.map(serializeResume);
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export function getContact(data: string): Contact {
  const phoneRegEx = new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$");
  const contactData = data.split(" ");

  return {
    email: contactData.find((contact) => validateEmail(contact)),
    phone_number: contactData.find((contact) => phoneRegEx.test(contact)),
    links: contactData
      .filter(
        (contact) =>
          contact && !validateEmail(contact) && !phoneRegEx.test(contact),
      )
      .map(setHttp),
    is_visible: true,
  };
}

export function serializeLocation(location: LocationType[]): LocationType {
  return location.length > 1 ? LocationType.Hybrid : location[0];
}
export function serializePosition(position: PositionType[]): PositionType {
  return position.length > 1 ? PositionType.Both : position[0];
}
export function deserializeLocation(location: LocationType): LocationType[] {
  return location == LocationType.Hybrid
    ? [LocationType.Office, LocationType.Remote]
    : [location];
}
export function deserializePosition(position: PositionType): PositionType[] {
  return position == PositionType.Both
    ? [PositionType.FullTime, PositionType.PartTime]
    : [position];
}

export function contactToString(data: Contact): string {
  if (!data) return "";
  let result = "";
  if (data.email) {
    result += String(data.email) + " ";
  }
  if (data.phone_number) {
    result += String(data.phone_number) + " ";
  }
  if (data.links.length != 0) {
    result = data.links.join(" ");
  }
  return result;
}

export function deserializeTestHistoryList(
  response: PaginatedList<TestHistoryResponse>,
): TestHistory[] {
  return response.results?.map(deserializeTestHistory) || [];
}

export function deserializeTestHistory(
  response: TestHistoryResponse,
): TestHistory {
  const attempts = response.attempts.map(deserializeTestAttempt);

  return {
    id: response.id,
    name: response.name,
    status: response.status,
    category: response.category,
    attempts: attempts,
    hasFreeText: attempts.some((attempt) => attempt.hasFreeText),
    hasVideoQuestion: attempts.some((attempt) => attempt.hasVideoQuestion),
    hasMultipleChoice: attempts.some((attempt) => attempt.hasMultipleChoice),
    isSuspicious: attempts.some((attempt) => attempt.isSuspicious),
    created_at: new Date(response.created_at),
    updated_at: new Date(response.updated_at),
  };
}
export function deserializeJobApplicantTestStats(
  testStats: JobApplicantTestStatsResponse,
): EmployerJobApplicantTestStats {
  return {
    windowMinimize: testStats.window_minimize,
    windowMinimizeCount: testStats.window_minimize_count,
    mouseOffWindow: testStats.mouse_off_window,
    mouseOffWindowCount: testStats.mouse_off_window_count,
    similarJobApplicantName: testStats.similar_applicant?.full_name,
    similarJobApplicantId: testStats.similar_applicant?.id,
    webcamEnabled: testStats.webcam_enabled,
    micEnabled: testStats.mic_enabled,
    pasteDetected: testStats.paste_detected,
    devices: testStats.devices,
    usingMultipleIps: testStats.using_multiple_ips,
    usingSameIps: testStats.has_similar_job_applicant,
    allIpUaPairs: testStats.all_ip_ua_res_pairs.map((v) => ({
      ipAddress: v.ip_address,
      userAgent: v.user_agent,
      screen_resolution: v.screen_resolution,
    })),
  };
}

export function deserializeTestAttempt(
  response: TestAttemptResponse,
): TestAttempt {
  const averageTimePerQuestion = avgTimePerQuestion(response);
  return {
    id: response.id,
    isFinished: response.is_finished,
    score: response.score,
    questions: response.questions.map(deserializeTestAttemptQuestion),
    createdAt: new Date(response.created_at),
    updatedAt: new Date(response.updated_at),
    attemptDuration: response.attempt_duration,
    benchmarkTimePerQuestion: response.attempt_benchmark_per_question,
    averageTimePerQuestion: averageTimePerQuestion,
    isSuspicious:
      averageTimePerQuestion &&
      averageTimePerQuestion < response.attempt_benchmark_per_question,
    hasFreeText: response.questions.some(
      (question) =>
        question.question_type === "si-free-text-question" ||
        question.question_type === "mi-free-text-question",
    ),
    hasMultipleChoice: response.questions.some(
      (question) =>
        question.question_type === "sa-multi-choice-question" ||
        question.question_type === "ma-multi-choice-question",
    ),
    hasVideoQuestion: response.questions.some(
      (question) => question.question_type === "video",
    ),
  };
}

export function deserializeTestAttemptQuestion(
  question: TestHistoryQuestionResponse,
): TestHistoryQuestion {
  const answers: string[] =
    {
      "sa-multi-choice-question": () => question.answers,
      video: () => [question.answer_text],
      "ma-multi-choice-question": () => question.answers,
      "si-free-text-question": () => [question.answer_text],
      "mi-free-text-question": () =>
        question.answer_text ? JSON.parse(question.answer_text) : [],
    }[question.question_type]?.() || [];

  const isFreeText = [
    "si-free-text-question",
    "mi-free-text-question",
  ].includes(question.question_type);

  const skipped =
    answers.length < 1 ||
    (isFreeText && answers.every((a) => getText(a).trim().length < 1));

  return {
    type: deserializeQuestionType({
      type: question.question_type,
      weighted: false,
      answer_type: null,
    }),
    title: question.question,
    text: question.question_text,
    answers,
    skipped,
    duration: question.duration,
    score: question.score,
  };
}

export const avgTimePerQuestion = (attempt: TestAttemptResponse) => {
  const questions = attempt.questions.filter(
    (question) => !question.question_type.endsWith("-free-text-question"),
  );

  if (questions.length == 0) return 0;
  if (questions.length == 1) {
    return questions[0].duration;
  }

  const durations = questions.map((q) => q.duration);
  const maxDuration = Math.max(...durations);
  return (
    questions
      .filter((q) => !(q.duration === maxDuration))
      .reduce((a, b) => a + b.duration, 0) /
    (questions.length - 1)
  );
};
