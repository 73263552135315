export enum TestStatus {
  NotStarted = "pending",
  Running = "in-progress",
  Finished = "finished",
  Completed = "completed",
  Exhausted = "exhausted",
  Unknown = "unknown",
}
export type ReferenceStatusType =
  | TestStatus.NotStarted
  | TestStatus.Running
  | TestStatus.Finished
  | TestStatus.Unknown;

export const testCompleteStatusList = [
  TestStatus.Completed,
  TestStatus.Exhausted,
  TestStatus.Finished,
];
export const testIncompleteStatusList = [
  TestStatus.NotStarted,
  TestStatus.Running,
];
