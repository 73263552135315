
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Input, SolidButton } from "components/atomic";
import { LabeledLine } from "components/atomic/labeled-line/labeled-line";
import { TwoPanes } from "components/layout/two-panes";
import { LinkedInButton } from "components/registration/linked-in-button";
import { useLogin } from "hooks/auth";
import { useTranslation } from "hooks/translation";
import Head from "next/head";
import Link from "next/link";
import React, { useMemo, useState } from "react";
import { addValidPropsWithMessage } from "utility/isValidField";
import { EMAIL_REGEX, NON_SPACE_CHARACTER_REGEX } from "utility/regex";
type Props = {
    onLogin?: () => void;
};
const Login = ({ onLogin }: Props) => {
    const { $t } = useTranslation();
    const [isValidated, setisValidated] = useState(false);
    const [email, setEmail] = useState(process.env.NEXT_PUBLIC_EMAIL || "");
    const [invalidEmails, setInvalidEmails] = useState([]);
    const [password, setPassword] = useState(process.env.NEXT_PUBLIC_PASSWORD || "");
    const { isLoading, login } = useLogin({
        email,
        password,
        onLogin,
        setInvalidEmail: (email) => setInvalidEmails((emails) => [...emails, email]),
    });
    const handleFormSubmit = async (e) => {
        if (loginValidityState.isValidForm) {
            e.preventDefault();
            login();
        }
        else {
            setisValidated(true);
        }
    };
    const handleInputKeyPress = (e) => {
        if (e.key === "Enter") {
            handleFormSubmit(e);
        }
    };
    const loginValidityState = useMemo(() => {
        const isValidEmail = {
            condition: EMAIL_REGEX.test(email),
            errorMessage: "Please enter a valid email",
        };
        const isValidPassword = {
            condition: NON_SPACE_CHARACTER_REGEX.test(password),
            errorMessage: "Please enter a valid password",
        };
        return {
            isValidForm: isValidEmail.condition && isValidPassword.condition,
            isValidEmail,
            isValidPassword,
        };
    }, [email, password]);
    return (<>
      <Head>
        <title>{$t("account:page-titles.login")} | PerfectlyHired</title>
      </Head>
      <TwoPanes heading={$t("account:sign-in.heading")} pitches={[$t("account:sign-in.pitch")]} alternatives={[
            {
                question: $t("account:job-seeker.question"),
                text: $t("account:job-seeker.heading"),
                url: `/register/job-seeker`,
            },
        ]}>
        <div className="space-y-2 lg:space-y-[30px]">
          <LinkedInButton action="sign-in"/>
          <LabeledLine>or</LabeledLine>
          <form className="space-y-4 lg:space-y-[30px]" onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
        }}>
            <Input borderColor="border-primary" paddingClass="px-5 py-4" height="56px" label={$t("account:email-address.label")} type="email" placeholder={$t("account:sign-in.placeholder.email")} value={email} onChange={setEmail} error={invalidEmails.includes(email)
            ? "Account not registered"
            : undefined} required onKeyPress={handleInputKeyPress} {...(isValidated &&
        addValidPropsWithMessage(loginValidityState.isValidEmail))}/>
            <Input borderColor="border-primary" paddingClass="px-5 py-4" height="56px" label={$t("account:password.label")} type="password" placeholder={$t("account:sign-in.placeholder.password")} value={password} onChange={setPassword} required onKeyPress={handleInputKeyPress} {...(isValidated &&
        addValidPropsWithMessage(loginValidityState.isValidPassword))}/>
            <p>
              <Link href="/auth/forgot-password">
                {$t("account:forgot-password.title")}
              </Link>
            </p>
            <div className="mt-6 flex items-center">
              {isLoading ? (<SolidButton className="w-full" disabled variant="disabled">
                  Loading...
                </SolidButton>) : (<SolidButton className="w-full" onClick={handleFormSubmit} variant="success">
                  {$t("account:sign-in.heading")}
                </SolidButton>)}
            </div>
          </form>
        </div>
      </TwoPanes>
    </>);
};
export default Login;

    async function __Next_Translate__getStaticProps__1948834b2ba__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/auth/login',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1948834b2ba__ as getStaticProps }
  